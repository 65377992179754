import React from 'react'
import Pusher from 'pusher-js'
import { PUSHER_CHANEL, PUSHER_CLUSTER, PUSHER_KEY } from 'config'

const PusherContext = React.createContext({
  pusher: null,
  chanel: {
    contributionChanel: null,
    stateTrackChanel: null,
  },
})
const PusherContextProvider = ({ children }: any) => {
  //TODO: Log to check env variables
  //eslint-disable-next-line
  const pusher = new Pusher(PUSHER_KEY || '', {
    cluster: PUSHER_CLUSTER || '',
    enabledTransports: ['ws'],
  })

  // subscribe to channel
  const contributionChanel = pusher.subscribe(PUSHER_CHANEL.CONTRIBUTIONTRACK)
  const stateTrackChanel = pusher.subscribe(PUSHER_CHANEL.STATETRACK)

  return (
    <PusherContext.Provider
      value={{
        pusher,
        chanel: {
          contributionChanel,
          stateTrackChanel,
        },
      }}
    >
      {children}
    </PusherContext.Provider>
  )
}

export { PusherContext, PusherContextProvider }
