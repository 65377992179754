import BigNumber from 'bignumber.js/bignumber'
import { BIG_TEN } from '../utils/bigNumber'
import { ConnectorNames } from './constants/types'
import { WalletReadyState } from '@solana/wallet-adapter-base'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

export const DEFAULT_TOKEN_DECIMAL = BIG_TEN.pow(18)

export const EVM_WALLETS = [
  {
    connector: ConnectorNames.Injected,
    adapter: {
      name: 'MetaMask',
      icon: '../assets/images/metamask1.svg',
    },
    readyState: WalletReadyState.Installed,
  },
]

export enum STATUSCODE {
  SUCCESS = 200,
  FAILURE = 500,
  UNAUTH = 404,
}

export enum SALE_CARD_EXISTANCE {
  PROFILE = 'profile',
  PROJECT = 'project'
}

export enum PUSHER_CHANEL {
  CONTRIBUTIONTRACK = 'projectContributionTrack',
  STATETRACK = 'projectStateTrack'
}

export * from './env';