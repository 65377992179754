import { Language } from 'config/constants/types'

export const EN: Language = {
  locale: 'en-US',
  language: 'English',
  code: 'en',
}

export const languages = {
  'en-US': EN,
}

export const languageList = Object.values(languages)
