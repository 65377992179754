import { useCallback, useEffect, useState } from 'react'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'

import { connectorsByName } from 'utils/web3React'
import { setupNetwork } from 'utils/wallet'
import { connectorLocalStorageKey, ConnectorNames } from 'config/constants/types'
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector'
import { authMessage } from 'config/constants'
import useTranslation from 'contexts/Localization/useTranslation'
import { authUserRequest } from 'state/user/queries/authUser'
import { useUserActionHandler, useUserManager } from 'state/user/hooks'
import { IWalletInfo } from 'state/wallets/types'
import { SupportedChainId } from 'config/constants/chains'
import { useWalletActionHandlers } from 'state/wallets/hooks'

const useAuth = () => {
  const { t } = useTranslation()
  const [isRequireAuth, setIsRequireAuth] = useState(false)
  const { account, chainId: metamaskActiveChainId, activate, deactivate } = useWeb3React()
  const [, setUser] = useUserManager()
  const [userSelectedChainId, setUserSelectedChainId] = useState<SupportedChainId>()
  const { setSignAuthLoading } = useUserActionHandler()
  const { onWalletStatusUpdate } = useWalletActionHandlers()
  const [error, setError] = useState(null)
  const [walletConnected, setWalletConnected] = useState<boolean>(false)

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null)
      }, 3000)
    }
  }, [error])

  useEffect(() => {
    const SignMessage = async () => {
      setSignAuthLoading(true)

      try {
        const signature = await window.ethereum.request({
          method: 'personal_sign',
          params: [authMessage, account, 'Random text'],
        })

        const _user = await authUserRequest(account, signature as any as string, authMessage)
        setUser(_user)
        if (_user?.jwt) {
          setWalletConnected(true)
        }
        //set wallet state in redux after successfully signIn
        const wallet: IWalletInfo = {
          chainId: userSelectedChainId,
        }
        onWalletStatusUpdate(wallet)
        setSignAuthLoading(false)
      } catch (error) {
        setWalletConnected(false)
        setSignAuthLoading(false)
      }
    }
    if (account !== undefined && isRequireAuth) {
      SignMessage()
      setIsRequireAuth(false)
    }
  }, [isRequireAuth, account])

  const connectEVM = useCallback(
    async (connectorID: ConnectorNames, chainId) => {
      setUserSelectedChainId(chainId)
      const connector = connectorsByName[connectorID]
      if (connector) {
        await setupNetwork(chainId)

        await activate(connector, async (error: Error) => {
          if (error instanceof NoEthereumProviderError) {
            setError('No provider was found')
          } else if (error instanceof UnsupportedChainIdError) {
            const hasSetup = await setupNetwork(chainId)
            if (hasSetup) {
              activate(connector)
            }
          } else {
            window.localStorage.removeItem(connectorLocalStorageKey)
            if (error instanceof UserRejectedRequestErrorInjected) {
              setError('Please authorize to access your account')
            } else {
              if (error?.message.includes('Already processing')) {
                setError('Kindly Check Your Wallet')
              }
            }
          }
        })
        setIsRequireAuth(true)
      } else {
        setError('Invalid wallet')
      }
    },
    // eslint-disable-next-line
    [t, activate, metamaskActiveChainId, account],
  )

  const disconnectEVM = useCallback(
    async () => {
      deactivate()
      setUser(null)
      onWalletStatusUpdate(null)
      setWalletConnected(false)
    },
    // eslint-disable-next-line
    [deactivate],
  )

  return { connectEVM, disconnectEVM, error, walletConnected }
}

export default useAuth
