import React, { FC, useMemo } from 'react'
import { ConnectionProvider, WalletProvider, useWallet } from '@solana/wallet-adapter-react'
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import {
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  TorusWalletAdapter,
} from '@solana/wallet-adapter-wallets'
import { Commitment } from '@solana/web3.js'
import { ENVIRONMENT } from 'config'
import { ENVIRONMENTS } from 'config/constants/types'

interface Props {
  children: React.ReactNode
}
export const SolanaWalletProvider: FC<Props> = ({ children }) => {
  const productionEnv = ENVIRONMENT === ENVIRONMENTS.PRODUCTION;
  const stagingEnv = ENVIRONMENT === ENVIRONMENTS.STAGING;
  let network, endpoint;
  
  if(!productionEnv && !stagingEnv){
   network = WalletAdapterNetwork.Devnet
   endpoint = 'https://api.devnet.solana.com'
  }
  else{
   network = WalletAdapterNetwork.Mainnet
   endpoint = 'https://solana-mainnet.g.alchemy.com/v2/MQwtjMKBpjhH0zus2hfsquy8vX0dCwAu'
  }


  const config = {
    commitment: 'processed' as Commitment,
  }
  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
  // Only the wallets you configure here will be compiled into your application, and only the dependencies
  // of wallets that your users connect to will be loaded.
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter({ network }),
      new SlopeWalletAdapter(),
      new SolflareWalletAdapter({ network }),
      new TorusWalletAdapter(),
      // new LedgerWalletAdapter(),
      // new SolletWalletAdapter({ network }),
      
      // new SolletExtensionWalletAdapter({ network }),
    ],
    [network],
  )

  return (
    <ConnectionProvider endpoint={endpoint} config={config}>
      <WalletProvider wallets={wallets}>{children}</WalletProvider>
    </ConnectionProvider>
  )
}

export const useSolanaWallet = useWallet
